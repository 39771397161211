import { useState, useEffect, useRef, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import gsap from "gsap";
import css from "./menu.module.scss";
import { useSwiperContext } from "../../hook/useSwiperContext";

const Menu = ({ swiperInstance }) => {
  const { dispatch, numContext } = useSwiperContext();
  const [isMenu, setIsMenu] = useState(null);
  const { pathname: location } = useLocation();
  const navigate = useNavigate();

  const menuBackground = useRef(null);
  const menuContainer = useRef(null);
  const menuNav = useRef(null);
  const menuBtn = useRef(null);
  const menuIcon = useRef(null);
  const menuItemFirst = useRef(null);
  const menuItemSecond = useRef(null);
  const menuItemThird = useRef(null);
  const menuItemFourth = useRef(null);
  const menuItemFifth = useRef(null);
  const menuItemSixth = useRef(null);
  const menuItemSeventh = useRef(null);
  const menuItemLogin = useRef(null);

  const handleLinks = (e) => {
    e.parentNode.style.transform = "translateX(25%)";
    setTimeout(() => {
      setIsMenu(false);
    }, 300);
    setTimeout(() => {
      e.parentNode.style.transform = "translateX(0%)";
    }, 2500);
    if (swiperInstance !== null && location === "/") {
    } else if (location !== "/") {
      setTimeout(() => navigate("/"), 0);
    }
  };

  useEffect(() => {
    if (isMenu === null) {
      gsap.set([menuBackground.current, menuContainer.current], { height: "0%", skewY: 2 });
      gsap.set(
        [
          menuItemFirst.current,
          menuItemSecond.current,
          menuItemThird.current,
          menuItemFourth.current,
          menuItemFifth.current,
          menuItemSixth.current,
          menuItemSeventh.current,
          menuItemLogin.current,
        ],
        { autoAlpha: 0 }
      );
    } else if (isMenu === false) {
      gsap.to(
        [
          menuItemFirst.current,
          menuItemSecond.current,
          menuItemThird.current,
          menuItemFourth.current,
          menuItemFifth.current,
          menuItemSixth.current,
          menuItemSeventh.current,
          menuItemLogin.current,
        ],
        {
          duration: 0.8,
          autoAlpha: 0,
          stagger: {
            amount: 0.1,
          },
        }
      );

      gsap.to(
        [menuContainer.current, menuBackground.current],
        {
          duration: 1,
          ease: "power3.inOut",
          transformOrigin: "top right",
          height: "0%",
          minHeight: "0%",
          skewY: 2,
          stagger: {
            amount: 0.1,
          },
        },
        "-=0.5"
      );

      menuIcon.current.classList.remove("burgerOpen");
    } else if (isMenu === true) {
      gsap.to([menuBackground.current, menuContainer.current], {
        duration: 1,
        ease: "power3.inOut",
        transformOrigin: "top right",
        height: "100%",
        minHeight: "100%",
        skewY: 0,
        stagger: {
          amount: 0.1,
        },
      });

      gsap.to(
        [
          menuItemFirst.current,
          menuItemSecond.current,
          menuItemThird.current,
          menuItemFourth.current,
          menuItemFifth.current,
          menuItemSixth.current,
          menuItemSeventh.current,
          menuItemLogin.current,
        ],
        {
          duration: 0.8,
          autoAlpha: 1,
          stagger: {
            amount: 0.1,
          },
        },
        "-=0.5"
      );

      menuIcon.current.classList.add("burgerOpen");
    }
  }, [isMenu]);

  useEffect(() => {
    setTimeout(() => {
      if (menuBtn.current && document.querySelector(".swiper-pagination")) {
        menuBtn.current.style.transition = "opacity 0.05s ease-in-out";
        document.querySelector(".swiper-pagination").style.transition = "opacity 0.05s ease-in-out";
        if (numContext === 7) {
          menuBtn.current.style.opacity = "0";
          menuBtn.current.style.pointerEvents = "none";
          document.querySelector(".swiper-pagination").style.opacity = "0";
          document.querySelector(".swiper-pagination").style.pointerEvents = "none";
        } else {
          menuBtn.current.style.opacity = "1";
          menuBtn.current.style.pointerEvents = "visible";
          document.querySelector(".swiper-pagination").style.opacity = "1";
          document.querySelector(".swiper-pagination").style.pointerEvents = "visible";
        }
      }
    }, 100);
  });

  return (
    <header id="header" className={css.container}>
      <div ref={menuBtn} className={css.btnContainer} onClick={() => setIsMenu(!isMenu)}>
        <span className={css.btnText}>Menu</span>
        <button ref={menuIcon} className={css.btn}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
      <div ref={menuBackground} className={css.background}></div>
      <div ref={menuContainer} className={css.menuContainer}>
        <nav ref={menuNav} className={css.nav}>
          <ul
            className={css.list}
            onClick={(e) => {
              if (e.target.tagName === "SPAN") {
                handleLinks(e.target);
              }
            }}
          >
            <li ref={menuItemFirst} className={css.link}>
              <span onClick={() => dispatch({ type: "1" })}>Concierge Services</span>
            </li>
            <li ref={menuItemSecond} className={css.link}>
              <span onClick={() => dispatch({ type: "2" })}>Tickets Worldwide</span>
            </li>
            <li ref={menuItemThird} className={css.link}>
              <span onClick={() => dispatch({ type: "3" })}>Premium Cards</span>
            </li>
            <li ref={menuItemFourth} className={css.link}>
              <span onClick={() => dispatch({ type: "4" })}>Business Jet Rental</span>
            </li>
            <li ref={menuItemFifth} className={css.link}>
              <span onClick={() => dispatch({ type: "5" })}>Exclusive Super Cars</span>
            </li>
            <li ref={menuItemSixth} className={css.link}>
              <span onClick={() => dispatch({ type: "6" })}>Experience</span>
            </li>
            <li ref={menuItemSeventh} className={css.link}>
              <span onClick={() => dispatch({ type: "7" })}>Contacts</span>
            </li>
          </ul>
          <Link to="/login" ref={menuItemLogin} className={css.login}>
            Login
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Menu;
