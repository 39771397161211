const SwiperModeReducer = (state, action) => {
  switch (action.type) {
    case "1": {
      return {
        numContext: 1,
      };
    }
    case "2": {
      return {
        numContext: 2,
      };
    }
    case "3": {
      return {
        numContext: 3,
      };
    }
    case "4": {
      return {
        numContext: 4,
      };
    }
    case "5": {
      return {
        numContext: 5,
      };
    }
    case "6": {
      return {
        numContext: 6,
      };
    }
    case "7": {
      return {
        numContext: 7,
      };
    }
    case "true": {
      return {
        transitionNextPage: true,
      };
    }
    case "false": {
      return {
        transitionNextPage: false,
      };
    }
    default:
      return state;
  }
};

export default SwiperModeReducer;
