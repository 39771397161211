import css from "./logo.module.scss";

const Logo = () => {
  return (
    <a href="/" className={css.logotype}>
      <img className={css.logotypeImg} src="/content/logo-first-slide.svg" alt="G01 logotype" />
    </a>
  );
};

export default Logo;
