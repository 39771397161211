import css from "./footer.module.scss";
import { useSwiper } from "swiper/react";
import { useMediaQuery } from "react-responsive";

const Footer = () => {
  const swiper = useSwiper();
  const isTablet = useMediaQuery({ query: "(max-width: 1199px)" });

  return (
    <div className={css.container}>
      <div className={css.footerAbout}>
        <span className={css.talk} onClick={() => swiper.slideTo(8)}>
          Let’s talk!
        </span>
      </div>
      <div className={css.exploreProject} onClick={() => swiper.slideNext()}>
        <img className={css.exploreProjectImg} src="/content/mouse.svg" alt="footer content" />
        <span className={css.exploreProjectText}>{isTablet ? "Swipe to explore project" : "Scroll to explore project"}</span>
      </div>
      <div className={css.footerAbout}>.</div>
    </div>
  );
};

export default Footer;
