import { useState } from "react";
import css from "./map.module.scss";

const Map = () => {
  const [address, setAddress] = useState("Dudley House, 169 Piccadilly Mayfair, London, W1J 9EH, United Kingdom");
  const [hrefAddres, setHrefAddres] = useState("https://goo.gl/maps/r1kpPSxJtAasPkBq7");
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div className={css.wrapper}>
      <div className={css.container}>
        <div
          className={
            toggleState === 1
              ? css.mapBackground + " " + css.imgLondon
              : toggleState === 2
              ? css.mapBackground + " " + css.imgAmsterdam
              : toggleState === 3
              ? css.mapBackground + " " + css.imgBahamas
              : css.mapBackground
          }
        ></div>
        <div className={css.mapLeft}>
          <div className={css.leftTitleFirst}>Contact information</div>
          <div className={css.leftTitleSecond}>Get in touch for enjoying our VIP Services</div>
          <div className={css.leftText}>
            From priority reservations at the most exclusive restaurants to luxury travel planning, VIP tickets to sold-out events
            to once-in-a lifetime experiences, we are here to enrich your life.
          </div>
        </div>
        <div className={css.mapRight}>
          <div className={css.rightTitle}>G01: vip consierge and lifestyle service</div>
          <div className={css.buttonWrapper}>
            <div className={css.buttonContainer}>
              <button
                className={toggleState === 1 ? css.buttonMap + " " + css.buttonMapActive : css.buttonMap}
                onClick={() => {
                  toggleTab(1);
                  setAddress("3 Westminster Tower, Albert Embankment London, SE1 7SR, United Kingdom");
                  setHrefAddres("https://goo.gl/maps/r1kpPSxJtAasPkBq7");
                }}
              >
                London
              </button>
              <button
                className={toggleState === 2 ? css.buttonMap + " " + css.buttonMapActive : css.buttonMap}
                onClick={() => {
                  toggleTab(2);
                  setAddress("Nijenburg 2A, 1081 GG Amsterdam Netherlands");
                  setHrefAddres("https://goo.gl/maps/cNTWvoCVKu52NDXCA");
                }}
              >
                Amsterdam
              </button>
              <button
                className={toggleState === 3 ? css.buttonMap + " " + css.buttonMapActive : css.buttonMap}
                onClick={() => {
                  toggleTab(3);
                  setAddress("CUB Financial Center, Lyford Cay, Nassau, Bahamas");
                  setHrefAddres("https://goo.gl/maps/1axfbghUSyEoFewT8");
                }}
              >
                Bahamas
              </button>
            </div>
            <a href={hrefAddres} className={css.addressOnMap} title="Adress" target="_blank">
              {address}
            </a>
          </div>
          <div className={css.feedback}>
            <div className={css.feedbackContainer + " " + css.email}>
              <div className={css.feedbackText}>Email:</div>
              <a href="mailto:g@g01.gg" title="g@g01.gg" target="_blank">
                g@g01.gg
              </a>
            </div>
            <div className={css.feedbackContainer + " " + css.phone}>
              <div className={css.feedbackText}>Phone:</div>
              <div className={css.phoneBlock}>
                <a href="tel:+447411111103" title="+447411111103" target="_blank">
                  +44 74 111 111 03
                </a>
                <a href="tel:+447511411111" title="+447511411111" target="_blank">
                  +44 74 111 111 11
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={css.copyright}>
          <a href="https://i01.ai/" target="_blank">
            Developed by The First Studio
          </a>
        </div>
      </div>
    </div>
  );
};

export default Map;
