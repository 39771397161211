import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ConciergeServices from "./pages/ConciergeServices";
import TicketsWorldwide from "./pages/TicketsWorldwide";
import PageNotFound from "./pages/PageNotFound";
import "./App.css";
import Preloader from "./components/Preloader";
import Menu from "./components/Menu";
import Transition from "./components/Transition";

function App() {
  return (
    <>
      <Preloader />
      <Menu />
      <Transition />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/concierge-services" element={<ConciergeServices />}>
          <Route path="home" element={<ConciergeServices pathName="home" />} />
          <Route path="transport" element={<ConciergeServices pathName="transport" />} />
          <Route path="events" element={<ConciergeServices pathName="events" />} />
          <Route path="family" element={<ConciergeServices pathName="family" />} />
          <Route path="wealth-management" element={<ConciergeServices pathName="wealth-management" />} />
          <Route path="gastronomy" element={<ConciergeServices pathName="gastronomy" />} />
          <Route path="golf" element={<ConciergeServices pathName="golf" />} />
          <Route path="hotelier" element={<ConciergeServices pathName="hotelier" />} />
          <Route path="wanderlust" element={<ConciergeServices pathName="wanderlust" />} />
        </Route>
        <Route path="/tickets-worldwide" element={<TicketsWorldwide />}>
          <Route path="awards" element={<TicketsWorldwide pathName="awards" />} />
          <Route path="fashion" element={<TicketsWorldwide pathName="fashion" />} />
          <Route path="film-and-theatre" element={<TicketsWorldwide pathName="film-and-theatre" />} />
          <Route path="music" element={<TicketsWorldwide pathName="music" />} />
          <Route path="society" element={<TicketsWorldwide pathName="society" />} />
          <Route path="sport" element={<TicketsWorldwide pathName="sport" />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
