import { useContext } from "react";
import { SwiperContext } from "../hoc/SwiperProvider";

// function init(state) {
//   console.log(state);
//   return { ...state };
// }

// function reducer(state, action) {
//   switch (action.type) {
//     case "reset":
//       return init(action.payload);

//     default:
//       return state;
//   }
// }

export const useSwiperContext = () => {
  return useContext(SwiperContext);
};
