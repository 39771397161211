import { useEffect, useRef, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Map from "../../components/Map";
import css from "./conciergeServices.module.scss";
import { useSwiperContext } from "../../hook/useSwiperContext";

const data = {
  home: {
    title: "Home",
    image: "/content/ConciergeServicesList/home-4k.png",
    items: ["Cleaning services", "Real estate agents", "Relocation service", "Architects and decor design", "Maintenance services"],
    text: "",
  },
  transport: {
    title: "Transport",
    image: "/content/ConciergeServicesList/transportation-4k.png",
    items: ["Private chauffeur", "Helicopter & private jets", "Luxury car hire", "Airport transfers", "Yachts charter"],
  },
  events: {
    title: "Events",
    image: "/content/ConciergeServicesList/events-4k.png",
    items: ["Society Gourmet", "Cinema lovers club", "Wine Connoisseurs"],
  },
  family: {
    title: "Family",
    image: "/content/ConciergeServicesList/familyAndChilds-4k.png",
    items: ["Babysitters and nannies", "Family holidays", "Education consultant", "Pets", "Family events"],
  },
  wealthManagement: {
    title: "Wealth management",
    image: "/content/ConciergeServicesList/wealthManagement-4k.png",
    items: [
      "Independent Financial Council",
      "Insurance and protection",
      "Investments and wealth",
      "Tax and legal advice",
      "Mortgage Management",
      "Retirement capital",
    ],
    text: "Wealth management service, supporting and advising clients on a wide range of needs including strategic planning, monitoring and reporting, asset allocation, tax planning. We provide oversight of your entire wealth management position, coordinating each part to ensure you have the right financial strategy in place to suit your very individual requirements.",
  },
  gastronomy: {
    title: "Gastronomy",
    image: "/content/ConciergeServicesList/gastronomy-4k.png",
    subtitle: "We offer a priority reservations at the most exclusive restaurants",
    text: "Our  team, including former Michelin Maître-d’s and food critics, keeps your up-to-date with the latest launches, where to dine to match your culinary tastes",
    testimonial: {
      author: "Domenic Guerrero",
      text: "This product is exactly what I was needed. Awesome construction and incredible comfort while using.",
    },
  },
  golf: {
    title: "Golf",
    image: "/content/ConciergeServicesList/golf-4k.png",
    subtitle: "Enjoy golf experiences, hospitality and VIP access to the most exclusive courses around the world",
    text: "Whether it's access to the most exclusive clubs or organising a corporate golf event, tickets to the masters or a golfing holiday — we are here to help",
    testimonial: {
      author: "Domenic Guerrero",
      text: "This product is exactly what I was needed. Awesome construction and incredible comfort while using.",
    },
  },
  hotelier: {
    title: "Hotelier",
    image: "/content/ConciergeServicesList/hotelier-4k.png",
    subtitle: "Hotel reservations and recommendations par excellence",
    text: "We are holds exclusive partnerships with world-renowned hotels across the World. With access to an exceptional collection of luxury and boutique hotels, our team can source you the most suitable hotel rooms and suites wherever you travel.",
    testimonial: {
      author: "Domenic Guerrero",
      text: "This product is exactly what I was needed. Awesome construction and incredible comfort while using.",
    },
  },
  wanderlust: {
    title: "Wanderlust",
    image: "/content/ConciergeServicesList/wanderlust-4k.png",
    items: ["Villas and Hotels", "Restaurants", "Transports"],
    testimonial: {
      author: "Domenic Guerrero",
      text: "This product is exactly what I was needed. Awesome construction and incredible comfort while using.",
    },
  },
};

const ConciergeServices = ({ pathName }) => {
  const [content, setContent] = useState({});
  const { pathname: location } = useLocation();

  useEffect(() => {
    switch (location) {
      case "/concierge-services/events":
        setContent(data["events"]);
        break;
      case "/concierge-services/family":
        setContent(data["family"]);
        break;
      case "/concierge-services/wealthManagement":
        setContent(data["wealthManagementt"]);
        break;
      case "/concierge-services/gastronomy":
        setContent(data["gastronomy"]);
        break;
      case "/concierge-services/golf":
        setContent(data["golf"]);
        break;
      case "/concierge-services/hotelier":
        setContent(data["hotelier"]);
        break;
      case "/concierge-services/wanderlust":
        setContent(data["wanderlust"]);
        break;
      case "/concierge-services/transport":
        setContent(data["transport"]);
        break;
      case "/concierge-services/home":
      default:
        setContent(data["home"]);
        break;
    }
  }, []);

  const noMobile = useMediaQuery({ query: "(min-width: 768px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1199px)" });

  useEffect(() => {
    if (isTablet) {
      data.home.image = "/content/ConciergeServicesList/home-mobail.png";
      data.home.items = [];
      data.home.text =
        "Property management service featuring a bespoke approach that will attend to your needs and requirements. We appreciate that most people live a very busy lifestyle and sometimes do not have the time to organise even the small things in life such as grocery shopping or dry cleaning.";

      data.transport.image = "/content/ConciergeServicesList/transportation-mobail.png";

      data.events.image = "/content/ConciergeServicesList/events-mobail.png";

      data.family.image = "/content/ConciergeServicesList/familyAndChilds-mobail.png";

      data.wealthManagement.image = "/content/ConciergeServicesList/wealthManagement-mobail.png";
      data.wealthManagement.items = [];

      data.gastronomy.image = "/content/ConciergeServicesList/gastronomy-mobail.png";

      data.golf.image = "/content/ConciergeServicesList/golf-mobail.png";

      data.hotelier.image = "/content/ConciergeServicesList/hotelier-mobail.png";

      data.wanderlust.image = "/content/ConciergeServicesList/wanderlust-mobail.png";
    }
  }, [isTablet]);

  const { dispatch } = useSwiperContext();

  return (
    <div className={css.wrapper}>
      <div className={css.roundAnimationText}></div>
      <div className={css.contentLeft}>
        <Link
          className={css.back}
          to="/"
          onClick={() => {
            dispatch({ type: "1" });
            dispatch({ type: "true" });
          }}
        >
          <h2>
            <span>02 </span>Concierge Services
          </h2>
        </Link>
        <h1 className={css.title}>{content.title}</h1>
      </div>
      <img className={css.leftImage} src={content.image} alt={content.title} />
      <div className={css.contentRight}>
        {content.items ? (
          <div className={css.itemsBlockWrapper}>
            {content.items?.map((item) => (
              <div className={css.itemsBlockContainer} key={item}>
                <div className={css.itemsBlockImg}>
                  <div className={css.itemsBlockImgStar}></div>
                </div>
                <p>{item}</p>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
        {content.subtitle ? <h2 className={css.subtitle}>{content.subtitle}</h2> : ""}
        {content.text ? <p className={css.text}>{content.text}</p> : ""}
        {content.testimonial ? (
          <div className={css.testimonial}>
            <p className={css.testimonialAuthor}>{content.testimonial?.author}</p>
            <p className={css.testimonialText}>{content.testimonial?.text}</p>
            <div className={css.testimonialStarContainer}>
              <img className={css.testimonialStar} src="/content/home/contact/review/star-fill.svg" alt="star" />
              <img className={css.testimonialStar} src="/content/home/contact/review/star-fill.svg" alt="star" />
              <img className={css.testimonialStar} src="/content/home/contact/review/star-fill.svg" alt="star" />
              <img className={css.testimonialStar} src="/content/home/contact/review/star-fill.svg" alt="star" />
              <img className={css.testimonialStar} src="/content/home/contact/review/star-fill.svg" alt="star" />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {noMobile ? <Map /> : ""}
    </div>
  );
};

export default ConciergeServices;
