import React from "react";
import { useLayoutEffect } from "react";
import { useMediaQuery } from "react-responsive";
import css from "./preloader.module.scss";

const Preloader = () => {
  const containerRef = React.useRef(null);
  const isTablet = useMediaQuery({ query: "(max-width: 1199px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  useLayoutEffect(() => {
    if (sessionStorage.hasOwnProperty("start", "preloadAnimation()")) {
      containerRef.current.remove();
    }
  }, []);

  const preloadEnd = (e) => {
    sessionStorage.setItem("start", "preloadAnimation()");
    const removePreloader = () => containerRef.current.remove();
    setTimeout(removePreloader, 2000);
    containerRef.current.classList.add(css.hiddenContainer);
    window.removeEventListener("mousewheel", preloadEnd);
    window.removeEventListener("touchmove", preloadEnd);
  };
  window.addEventListener("mousewheel", preloadEnd);
  window.addEventListener("touchmove", preloadEnd);
  return (
    <div ref={containerRef} className={css.container}>
      <div className={css.grayStar}></div>
      <img className={css.starLeftFirst} src="./content/avaround-white.svg" alt="star" />
      <img className={css.starLeftSecond} src="./content/animationStar/star-small.svg" alt="star" />
      <img className={css.starRightFirst} src="./content/animationStar/star-small.svg" alt="star" />
      <img className={css.starRightSecond} src="./content/animationStar/star-small.svg" alt="star" />
      <div className={css.textConciergeServices}></div>
      <div className={css.exploreProject}>
        <img className={css.exploreProjectImg} src={isMobile ? "/content/arrow.svg" : "/content/mouse.svg"} alt="footer content" />
        <span className={css.exploreProjectText}>{isTablet ? "Swipe to explore project" : "Scroll to explore project"}</span>
      </div>
    </div>
  );
};

export default Preloader;
