import { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import css from "./transition.module.scss";
import { useSwiperContext } from "../../hook/useSwiperContext";

const Transition = () => {
  const { dispatch, transitionNextPage } = useSwiperContext();

  const transitionWrapper = useRef(null);
  const transitionBackground = useRef(null);
  const transitionContainer = useRef(null);

  useEffect(() => {
    if (transitionNextPage === null) {
      transitionWrapper.current.style.display = "none";
      gsap.set([transitionBackground.current, transitionContainer.current], { height: "0%", skewY: 2 });
    } else if (transitionNextPage === false) {
      transitionWrapper.current.style.display = "none";
      gsap.to(
        [transitionContainer.current, transitionBackground.current],
        {
          duration: 1,
          ease: "power3.inOut",
          transformOrigin: "top right",
          height: "0%",
          minHeight: "0%",
          skewY: 2,
          stagger: {
            amount: 0.1,
          },
        },
        "-=0.5"
      );
    } else if (transitionNextPage === true) {
      transitionWrapper.current.style.display = "block";
      setTimeout(() => {
        dispatch({ type: "false" });
      }, 1500);
      gsap.to([transitionBackground.current, transitionContainer.current], {
        duration: 1,
        ease: "power3.inOut",
        transformOrigin: "top right",
        height: "100%",
        minHeight: "100%",
        skewY: 0,
        stagger: {
          amount: 0.1,
        },
      });
    }
  }, [transitionNextPage]);

  return (
    <>
      <div ref={transitionWrapper} className={css.transitionWrapper}></div>
      <div ref={transitionBackground} className={css.background}></div>
      <div ref={transitionContainer} className={css.transitionContainer}></div>
    </>
  );
};

export default Transition;
