import { useReducer } from "react";
import { createContext, useState } from "react";
import SwiperModeReducer from "./swiperModeReducer";

const INITIAL_STATE = { numContext: 0, transitionNextPage: null };

export const SwiperContext = createContext(INITIAL_STATE);

export const SwiperProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SwiperModeReducer, INITIAL_STATE);

  const value = { numContext: state.numContext, transitionNextPage: state.transitionNextPage, dispatch };

  return <SwiperContext.Provider value={value}>{children}</SwiperContext.Provider>;
};
