import React from 'react'
import { useEffect } from 'react';
import { useSwiper } from 'swiper/react'

const SwiperInstance = ({ setSwiperInstance }) => {
  const swiper = useSwiper();

  useEffect(() => {
    setSwiperInstance(swiper);
  });

  return (
    <div></div>
  );
};

export default SwiperInstance;
