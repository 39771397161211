import { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import css from "./home.module.scss";
import Logo from "../../components/Logo";
import Footer from "../../components/Footer";
import Map from "../../components/Map";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel, EffectFade } from "swiper";
import SwiperInstance from "../../components/SwiperInstance";
import "swiper/css/bundle";
import SwiperContact from "../../components/SwiperContact";
import { useSwiperContext } from "../../hook/useSwiperContext";
import { useMediaQuery } from "react-responsive";

const Home = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [firstSlideContent, setFirstSlideContent] = useState(true);
  const [swiper, setSwiper] = useState(null);
  const [showSwiperContact, setSwiperContact] = useState(false);
  const { numContext, dispatch } = useSwiperContext();

  const noMobile = useMediaQuery({ query: "(min-width: 768px)" });
  let forceTouch = noMobile ? 70 : 0;

  const TogleFirstSlideLeftContent = () => setFirstSlideContent(!firstSlideContent);

  const windowHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  };

  useEffect(() => {
    windowHeight();
  }, []);

  useEffect(() => {
    const slideTo = (index) => {
      if (!swiper || swiper.destroyed) {
        return;
      }
      const slideTo = () => swiper.slideTo(index);
      setTimeout(slideTo, 100);
    };

    slideTo(numContext);
  }, [swiper, numContext]);

  useEffect(() => {
    if (!swiper || swiper.destroyed) {
      return;
    }
    const hiddenMenuAndPagination = () => {
      if (swiper.realIndex === 1) {
        dispatch({ type: "1" });
      } else if (swiper.realIndex === 2) {
        dispatch({ type: "2" });
      } else if (swiper.realIndex === 3) {
        dispatch({ type: "3" });
      } else if (swiper.realIndex === 4) {
        dispatch({ type: "4" });
      } else if (swiper.realIndex === 5) {
        dispatch({ type: "5" });
      } else if (swiper.realIndex === 6) {
        dispatch({ type: "6" });
      } else if (swiper.realIndex === 7) {
        dispatch({ type: "7" });
      }
    };
    setInterval(hiddenMenuAndPagination, 500);

    const toggleSwiperContact = () => {
      swiper.realIndex === 7 ? setSwiperContact(true) : setSwiperContact(false);
    };
    setInterval(toggleSwiperContact, 500);
  }, [swiper, numContext]);

  return (
    <>
      <Swiper
        onSwiper={setSwiper}
        className={"swiperHome"}
        modules={[Pagination, Mousewheel, EffectFade]}
        effect="fade"
        direction={"vertical"}
        speed={1000}
        slidesPerView={1}
        spaceBetween={0}
        pagination={{ clickable: true }}
        mousewheel={{ forceToAxis: true }}
        touchRatio={1}
        width={70}
        threshold={forceTouch}
        preventClicks={true}
        preventClicksPropagation={true}
        shortSwipes={!noMobile}
        longSwipesRatio={0.3}
      >
        <SwiperInstance setSwiperInstance={setSwiperInstance} />
        <SwiperSlide className={css.slide + " " + css.firstSlide}>
          <Logo />
          <div className={css.firstSlideHead}></div>
          <div
            className={
              firstSlideContent ? css.slideLeft + " " + css.firstSlideLeft : css.slideLeft + " " + css.firstSlideLeft + " " + css.firstSlideLeftActive
            }
            onClick={TogleFirstSlideLeftContent}
          >
            <div className={css.firstFirstSlideContent}>
              <span className={css.firstSlideNum}>01</span>
              <h1 className={css.firstSlideTitle}>The G01</h1>
              <h2 className={css.slideSubtitle + " " + css.firstSlideSubtitle}>VIP concierge and lifestyle services</h2>
            </div>
            <div className={css.secondFirstSlideContent}>
              <span className={css.firstSlideNum}>01</span>
              <h1 className={css.firstSlideTitle}>The G01</h1>
              <h2 className={css.firstSlideSecondSubtitle}>24/7/365 Personal VIP Concierge and lifestyle service</h2>
              <p className={css.firstSlideText}>
                The service of a personal concierge is the crowning achievement of our array of benefits. Whatever support you require; your concierge
                is just a call or a message away.
              </p>
              <p className={css.firstSlideText}>
                Reliable, resourceful and a creative problem solver, your concierge will enrich day-to-day experiences, while saving you valuable
                time.
              </p>
            </div>
          </div>
          <div className={css.firstSlideRight}>
            <picture>
              <source
                media="(max-width: 767px)"
                className={css.firstSlideImg}
                srcSet="/content/home/first-slide/first-slide-right-mobile.png"
                alt="G01 content"
              />
              <source
                media="(max-width: 1199px)"
                className={css.firstSlideImg}
                srcSet="/content/home/first-slide/first-slide-right-tablet.png"
                alt="G01 content"
              />
              <img className={css.firstSlideImg} src="/content/home/first-slide/first-slide-right.png" alt="G01 content" />
            </picture>
          </div>
          <Footer />
        </SwiperSlide>
        <SwiperSlide className={css.slide + " " + css.conciergeServices}>
          <Logo />
          <div className={css.slideLeft + " " + css.contentListLeft}>
            <span className={css.numSlide}>02</span>
            <h3 className={css.slideSubtitle}>Concierge Services</h3>
            <ul
              className={css.list}
              onClick={(e) => {
                if (e.target.tagName === "A") {
                  dispatch({ type: "true" });
                }
              }}
            >
              <li className={css.link}>
                <Link className={css.animationLinkBlock} to="/concierge-services/home">
                  <span className={css.linkArrow}></span>
                  Home
                </Link>
              </li>
              <li className={css.link}>
                <Link className={css.animationLinkBlock} to="/concierge-services/transport">
                  <span className={css.linkArrow}></span>
                  Transportation
                </Link>
              </li>
              <li className={css.link}>
                <Link className={css.animationLinkBlock} to="/concierge-services/events">
                  <span className={css.linkArrow}></span>
                  Events
                </Link>
              </li>
              <li className={css.link}>
                <Link className={css.animationLinkBlock} to="/concierge-services/family">
                  <span className={css.linkArrow}></span>
                  Family and Childs
                </Link>
              </li>
              <li className={css.link}>
                <Link className={css.animationLinkBlock} to="/concierge-services/wealth-management">
                  <span className={css.linkArrow}></span>
                  Wealth management
                </Link>
              </li>
              <li className={css.link}>
                <Link className={css.animationLinkBlock} to="/concierge-services/gastronomy">
                  <span className={css.linkArrow}></span>
                  Gastronomy
                </Link>
              </li>
              <li className={css.link}>
                <Link className={css.animationLinkBlock} to="/concierge-services/golf">
                  <span className={css.linkArrow}></span>
                  Golf
                </Link>
              </li>
              <li className={css.link}>
                <Link className={css.animationLinkBlock} to="/concierge-services/hotelier">
                  <span className={css.linkArrow}></span>
                  Hotelier
                </Link>
              </li>
              <li className={css.link}>
                <Link className={css.animationLinkBlock} to="/concierge-services/wanderlust">
                  <span className={css.linkArrow}></span>
                  Wanderlust
                </Link>
              </li>
            </ul>
          </div>
          <div className={css.contentListRight}>
            <picture>
              <source
                media="(max-width: 767px)"
                className={css.contentListRightImg}
                srcSet="/content/home/ConciergeServices/ConciergeServices-right-mobile.png"
                alt="ConciergeServices content"
              />
              <source
                media="(max-width: 1599px)"
                className={css.contentListRightImg}
                srcSet="/content/home/ConciergeServices/ConciergeServices-right-desctop.png"
                alt="ConciergeServices content"
              />
              <source
                media="(max-width: 2159px)"
                className={css.contentListRightImg}
                srcSet="/content/home/ConciergeServices/ConciergeServices-right-2k.png"
                alt="ConciergeServices content"
              />
              <img className={css.contentListRightImg} src="/content/home/ConciergeServices/ConciergeServices-right-4k.png" alt="G01 content" />
            </picture>
          </div>
          <Footer />
        </SwiperSlide>
        <SwiperSlide className={css.slide + " " + css.ticketsWorldwide}>
          <Logo />
          <div className={css.slideLeft + " " + css.contentListLeft}>
            <span className={css.numSlide}>03</span>
            <h3 className={css.slideSubtitle}>Tickets Worldwide</h3>
            <ul
              className={css.list}
              onClick={(e) => {
                if (e.target.tagName === "A") {
                  dispatch({ type: "true" });
                }
              }}
            >
              <li className={css.link}>
                <Link className={css.animationLinkBlock} to="/tickets-worldwide/awards">
                  <span className={css.linkArrow}></span>
                  Awards
                </Link>
              </li>
              <li className={css.link}>
                <Link className={css.animationLinkBlock} to="/tickets-worldwide/fashion">
                  <span className={css.linkArrow}></span>
                  Fashion
                </Link>
              </li>
              <li className={css.link}>
                <Link className={css.animationLinkBlock} to="/tickets-worldwide/film-and-theatre">
                  <span className={css.linkArrow}></span>
                  Film&nbsp;&&nbsp;Theatre
                </Link>
              </li>
              <li className={css.link}>
                <Link className={css.animationLinkBlock} to="/tickets-worldwide/music">
                  <span className={css.linkArrow}></span>
                  Music
                </Link>
              </li>
              <li className={css.link}>
                <Link className={css.animationLinkBlock} to="/tickets-worldwide/society">
                  <span className={css.linkArrow}></span>
                  Society
                </Link>
              </li>
              <li className={css.link}>
                <Link className={css.animationLinkBlock} to="/tickets-worldwide/sport">
                  <span className={css.linkArrow}></span>
                  Sport
                </Link>
              </li>
            </ul>
          </div>
          <div className={css.contentListRight}>
            <picture>
              <source
                media="(max-width: 767px)"
                className={css.contentListRightImg}
                srcSet="/content/home/TicketsWorldwide/TicketsWorldwide-right-mobile.png"
                alt="ConciergeServices content"
              />
              <source
                media="(max-width: 1199px)"
                className={css.contentListRightImg}
                srcSet="/content/home/TicketsWorldwide/TicketsWorldwide-right-tablet.png"
                alt="ConciergeServices content"
              />
              <source
                media="(max-width: 1599px)"
                className={css.contentListRightImg}
                srcSet="/content/home/TicketsWorldwide/TicketsWorldwide-right-desctop.png"
                alt="ConciergeServices content"
              />
              <img className={css.contentListRightImg} src="/content/home/TicketsWorldwide/TicketsWorldwide-right.png" alt="G01 content" />
            </picture>
          </div>
          <Footer />
        </SwiperSlide>
        <SwiperSlide className={css.slide + " " + css.premiumCards}>
          <Logo />
          <div className={css.slideLeft}>
            <span className={css.numSlide}>04</span>
            <h3 className={css.slideSubtitle + " " + css.premiumCardsSubtitle + " " + "translateAnimationFirst"}>
              Premium <br /> Credit & Debit Cards
            </h3>
            <div className={css.slideTextPremiumCards}>
              <p className={css.slideText + " " + "translateAnimationSecond"}>
                Looking for a Card that offers you more? Our Premium Credit and Debit Cards could enhance your lifestyle.
              </p>
              <p className={css.slideText + " " + "translateAnimationThird"}>A desirable portfolio of super-premium and premium payment cards.</p>
              <p className={css.slideText + " " + "translateAnimationFourth"}>For business or for the individual, say Yes to Impossible.</p>
            </div>
          </div>
          <div className={css.premiumCardsRight}>
            <picture>
              <source className={css.premiumCardsRightImg} media="(max-width: 767px)" srcSet="/content/home/cards/cards-mobail.png" />
              <img className={css.premiumCardsRightImg} src="/content/home/cards/cards-4k.png" />
            </picture>
          </div>
          <Footer />
        </SwiperSlide>
        <SwiperSlide className={css.slide + " " + css.iJet}>
          <Logo />
          <div className={css.slideLeft}>
            <span className={css.numSlide}>05</span>
            <h3 className={css.slideSubtitle + " " + css.iJetSubtitle + " " + "translateAnimationFirst"}>
              iJet Aviation is our new, own exclusive jet supplier
            </h3>
            <p className={css.slideText + " " + css.iJetText + " " + "translateAnimationSecond"}>
              With offices in London, Amsterdam and Hong Kong, and a globally available fleet of business jets — iJet provides a global solution for
              your luxury aviation needs.
            </p>
            <a className={css.slideButton + " " + "translateAnimationThird"} href="https://ijet.gg" target="_blank" rel="noreferrer">
              Give me a jet!
            </a>
          </div>
          <div className={css.iJetRightFirst}>
            <picture>
              <source media="(max-width: 767px)" className={css.iJetRightFirstImg} srcSet="/content/home/iJet/iJet-airplane1-mobail.png" />
              <source media="(max-width: 1599px)" className={css.iJetRightFirstImg} srcSet="/content/home/iJet/iJet-airplane1-2k.png" />
              <img className={css.iJetRightFirstImg} src="/content/home/iJet/iJet-airplane1-4k.png" />
            </picture>
          </div>
          <div className={css.iJetRightSecond}>
            <picture>
              <source className={css.iJetRightSecondImg} media="(max-width: 767px)" srcSet="/content/home/iJet/iJet-airplane2-mobail.png" />
              <source className={css.iJetRightFirstImg} media="(max-width: 1599px)" srcSet="/content/home/iJet/iJet-airplane2-2k.png" />
              <img className={css.iJetRightSecondImg} src="/content/home/iJet/iJet-airplane2-2k.png" />
            </picture>
          </div>
          <Footer />
        </SwiperSlide>
        <SwiperSlide className={css.slide + " " + css.motors}>
          <Logo />
          <div className={css.slideLeft}>
            <span className={css.numSlide}>06</span>
            <h3 className={css.slideSubtitle + " " + css.motorsSubtitle + " " + "translateAnimationFirst"}>
              The most juicy offers from the world of super motors
            </h3>
            <div className={css.motorsText}>
              <p className={css.slideText + " " + "translateAnimationSecond"}>
                Legion Motors is our own auto trader for the sale and rental of exclusive supercars.
              </p>
              <p className={css.slideText + " " + "translateAnimationThird"}>Only for our clients.</p>
            </div>
            <a className={css.slideButton + " " + "translateAnimationFourth"} href="https://lmotor.gg" target="_blank" rel="noreferrer">
              Let's drive!
            </a>
          </div>
          <div className={css.motorsRight}>
            <picture>
              <source media="(max-width: 767px)" className={css.motorsRightImg} srcSet="/content/home/motors/helmet-mobail.png" />
              <source media="(max-width: 1599px)" className={css.motorsRightImg} srcSet="/content/home/motors/helmet-2k.png" />
              <img className={css.motorsRightImg} src="/content/home/motors/helmet-4k.png" />
            </picture>
          </div>
          <div className={css.motorsRightLogo}>
            <img className={css.motorsRightLogoImg} src="/content/home/motors/LegionMotors_logo.svg" />
          </div>
          <Footer />
        </SwiperSlide>
        <SwiperSlide className={css.slide + " " + css.experience}>
          <Logo />
          <div className={css.slideLeft}>
            <span className={css.numSlide}>07</span>
            <h3 className={css.slideSubtitle + " " + "translateAnimationFirst"}>Experience</h3>
            <div className={css.experienceText}>
              <p className={css.slideText + " " + "translateAnimationSecond"}>
                Have you ever wanted to train with a premiership football club, play tennis against Boris Becker, go through astronaut training, or
                create your own wine.
              </p>
              <p className={css.slideText + " " + "translateAnimationThird"}>
                Maybe you want front row seats at Paris Fashion Week, or to drive with the Stig, or enjoy backstage passes to meet your favorite band?
              </p>
              <p className={css.slideText + " " + "translateAnimationFourth"}>Wherever you are in the world, our team will organize everything.</p>
            </div>
          </div>
          <div className={css.experienceRight}>
            <picture>
              <source media="(max-width: 767px)" className={css.experienceRightImg} srcSet="/content/home/experience/statue-mobail.png" />
              <source media="(max-width: 1199px)" className={css.experienceRightImg} srcSet="/content/home/experience/statue-laptop.png" />
              <img className={css.experienceRightImg} src="/content/home/experience/statue-4k.png" />
            </picture>
          </div>
          <Footer />
        </SwiperSlide>
        <SwiperSlide className={css.slide + " " + css.contact}>
          <h3 className={css.contactTitle + " " + "translateAnimationFirst"}>What The People Thinks About Us </h3>
          {showSwiperContact ? <SwiperContact /> : null}
          <Map />
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Home;
