import { useEffect, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Map from "../../components/Map";
import css from "../ConciergeServices/conciergeServices.module.scss";
import { useSwiperContext } from "../../hook/useSwiperContext";

const data = {
  awards: {
    title: "Awards",
    image: "/content/TicketsWorldwideList/awards-4k.png",
    items: ["Fashion Awards", "The Oscars", "Other Awards"],
    testimonial: {
      author: "Domenic Guerrero",
      text: "This product is exactly what I was needed. Awesome construction and incredible comfort while using.",
    },
  },
  fashion: {
    title: "Fashion",
    image: "/content/TicketsWorldwideList/fashion-4k.png",
    items: ["New York Fashion Week", "Milan Fashion Week", "Other Fashion Events"],
    testimonial: {
      author: "Domenic Guerrero",
      text: "This product is exactly what I was needed. Awesome construction and incredible comfort while using.",
    },
  },
  filmAndTheatre: {
    title: "Film and Theatre",
    image: "/content/TicketsWorldwideList/filmAndTheatre-4k.png",
    items: ["Cannes Film Festival", "Theatre, Opera and Attractions", "Cinema Premieres"],
    testimonial: {
      author: "Domenic Guerrero",
      text: "This product is exactly what I was needed. Awesome construction and incredible comfort while using.",
    },
  },
  music: {
    title: "Music",
    image: "/content/TicketsWorldwideList/music-4k.png",
    items: ["Coachella", "Burning Man", "Festivals and Concerts"],
    testimonial: {
      author: "Domenic Guerrero",
      text: "This product is exactly what I was needed. Awesome construction and incredible comfort while using.",
    },
  },
  society: {
    title: "Society",
    image: "/content/TicketsWorldwideList/society-4k.png",
    items: ["Car Festivals", "Art & Culrure", "Galas"],
    testimonial: {
      author: "Domenic Guerrero",
      text: "This product is exactly what I was needed. Awesome construction and incredible comfort while using.",
    },
  },
  sport: {
    title: "Society",
    image: "/content/TicketsWorldwideList/sport-4k.png",
    items: ["F1 Rides", "Tennis", "Football"],
    testimonial: {
      author: "Domenic Guerrero",
      text: "This product is exactly what I was needed. Awesome construction and incredible comfort while using.",
    },
  },
};

const TicketsWorldwide = ({ pathName }) => {
  const [content, setContent] = useState({});
  const { pathname: location } = useLocation();

  useEffect(() => {
    switch (location) {
      case "/tickets-worldwide/fashion":
        setContent(data["fashion"]);
        break;
      case "/tickets-worldwide/film-and-theatre":
        setContent(data["filmAndTheatre"]);
        break;
      case "/tickets-worldwide/music":
        setContent(data["music"]);
        break;
      case "/tickets-worldwide/society":
        setContent(data["society"]);
        break;
      case "/tickets-worldwide/sport":
        setContent(data["sport"]);
        break;
      case "/tickets-worldwide/awards":
      default:
        setContent(data["awards"]);
        break;
    }
  }, []);

  const noMobile = useMediaQuery({ query: "(min-width: 768px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1199px)" });

  useEffect(() => {
    if (isTablet) {
      data.awards.image = "/content/TicketsWorldwideList/awards-mobail.png";

      data.fashion.image = "/content/TicketsWorldwideList/fashion-mobail.png";

      data.filmAndTheatre.image = "/content/TicketsWorldwideList/filmAndTheatre-mobail.png";

      data.music.image = "/content/TicketsWorldwideList/music-mobail.png";

      data.society.image = "/content/TicketsWorldwideList/society-mobail.png";

      data.sport.image = "/content/TicketsWorldwideList/sport-mobail.png";
    }
  }, [isTablet]);

  const { dispatch } = useSwiperContext();

  return (
    <div className={css.wrapper}>
      <div className={css.roundAnimationText}></div>
      <div className={css.contentLeft}>
        <Link
          className={css.back}
          to="/"
          onClick={() => {
            dispatch({ type: "2" });
            dispatch({ type: "true" });
          }}
        >
          <h2>
            <span>03 </span>Tickets Worldwide
          </h2>
        </Link>
        <h1 className={css.title}>{content.title}</h1>
      </div>
      <img className={css.leftImage} src={content.image} alt={content.title} />
      <div className={css.contentRight}>
        {content.items ? (
          <div className={css.itemsBlockWrapper}>
            {content.items?.map((item) => (
              <div className={css.itemsBlockContainer} key={item}>
                <div className={css.itemsBlockImg}>
                  <div className={css.itemsBlockImgStar}></div>
                </div>
                <p>{item}</p>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
        {content.subtitle ? <h2 className={css.subtitle}>{content.subtitle}</h2> : ""}
        {content.text ? <p className={css.text}>{content.text}</p> : ""}
        {content.testimonial ? (
          <div className={css.testimonial}>
            <p className={css.testimonialAuthor}>{content.testimonial?.author}</p>
            <p className={css.testimonialText}>{content.testimonial?.text}</p>
            <div className={css.testimonialStarContainer}>
              <img className={css.testimonialStar} src="/content/home/contact/review/star-fill.svg" alt="star" />
              <img className={css.testimonialStar} src="/content/home/contact/review/star-fill.svg" alt="star" />
              <img className={css.testimonialStar} src="/content/home/contact/review/star-fill.svg" alt="star" />
              <img className={css.testimonialStar} src="/content/home/contact/review/star-fill.svg" alt="star" />
              <img className={css.testimonialStar} src="/content/home/contact/review/star-fill.svg" alt="star" />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {noMobile ? <Map /> : ""}
    </div>
  );
};

export default TicketsWorldwide;
