import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import { SwiperProvider } from "./hoc/SwiperProvider";

function Main() {
  return (
    <SwiperProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </BrowserRouter>
    </SwiperProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Main />);
