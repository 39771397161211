import React from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import css from "./swiperContact.module.scss";

SwiperCore.use([Navigation]);

const SwiperContact = () => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  return (
    <div className={css.reviewContainer}>
      <Swiper
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        breakpoints={{
          2160: {
            slidesPerView: 3,
            spaceBetween: 45,
          },
          1600: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 1,
          },
        }}
      >
        <SwiperSlide className={css.reviewBlock}>
          <img className={css.reviewQuotes} src="/content/home/contact/review/quotes.svg" alt="review" />
          <div className={css.reviewName}>Domenic Guerrero</div>
          <div className={css.reviewText}>This product is exactly what I was needed. Awesome construction and incredible comfort while using.</div>
          <div className={css.reviewStarsContainer}>
            <img src="/content/home/contact/review/star-fill.svg" alt="star" />
            <img src="/content/home/contact/review/star-fill.svg" alt="star" />
            <img src="/content/home/contact/review/star-fill.svg" alt="star" />
            <img src="/content/home/contact/review/star-fill.svg" alt="star" />
            <img src="/content/home/contact/review/star-fill.svg" alt="star" />
          </div>
        </SwiperSlide>
        <SwiperSlide className={css.reviewBlock} style={{ pointerEvents: "visible" }}>
          <img className={css.reviewQuotes} src="/content/home/contact/review/quotes.svg" alt="review" />
          <div className={css.reviewName}>Alexandera Machec</div>
          <div className={css.reviewText}>Get in touch and start enjoying our VIP Services.</div>
          <div className={css.reviewStarsContainer}>
            <img src="/content/home/contact/review/star-fill.svg" alt="star" />
            <img src="/content/home/contact/review/star-fill.svg" alt="star" />
            <img src="/content/home/contact/review/star-fill.svg" alt="star" />
            <img src="/content/home/contact/review/star-fill.svg" alt="star" />
            <img src="/content/home/contact/review/star-union.svg" alt="star" />
          </div>
        </SwiperSlide>
        <SwiperSlide className={css.reviewBlock} style={{ pointerEvents: "visible" }}>
          <img className={css.reviewQuotes} src="/content/home/contact/review/quotes.svg" alt="review" />
          <div className={css.reviewName}>Austin Totten</div>
          <div className={css.reviewText}>I need to file my end-of-year taxes. Please can you put me in contact with an affordable accountant?</div>
          <div className={css.reviewStarsContainer}>
            <img src="/content/home/contact/review/star-fill.svg" alt="star" />
            <img src="/content/home/contact/review/star-fill.svg" alt="star" />
            <img src="/content/home/contact/review/star-fill.svg" alt="star" />
            <img src="/content/home/contact/review/star-union.svg" alt="star" />
            <img src="/content/home/contact/review/star-empty.svg" alt="star" />
          </div>
        </SwiperSlide>
        <div className={css.swiperArrowReviews}>
          <button className={css.swiperArrowReviewsPrev} ref={navigationPrevRef}></button>
          <button className={css.swiperArrowReviewsNext} ref={navigationNextRef}></button>
        </div>
      </Swiper>
    </div>
  );
};

export default SwiperContact;
